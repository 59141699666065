<template>
  <div>
    <div class="app-main-scroll-wrap content">
      <div style="display: flex; justify-content: space-between;align-items: center;">
        <div class="col toolbar-title">导入订单详情</div>
        <div style="display: flex; align-items: center;">
          <el-button @click="goBack" size="small" class="back_btn">
            <img src="@/assets/back.png" alt="">
          </el-button>
        </div>
      </div>
      <div style="padding:0 60px;margin-top: 40px;">
        <div style="margin: 20px 0;">
          <span>导入批次：{{ cartsInfo.data.batch_no }}</span>
          <span style="margin: 0 40px;">导入时间：{{ cartsInfo.data.trade_batch_do.create_time | unixToDate }}</span>
          <span>导入单状态：{{ ['待支付', '已支付', '已取消'][cartsInfo.data.trade_batch_do.status] }}</span>
        </div>
        <div v-show="tableData.length">
          <el-table :data="tableData" border style="width: 100%" header-cell-class-name="headerStyle1"
            :span-method="arraySpanMethod" :cell-class-name="cellStyle">
            <el-table-column label="商品图片" width="126" class-name="goods-cover-wrapper">
              <template slot-scope="{row}">
                <el-popover placement="right" trigger="hover">
                  <img :src="row.web_thumbnail || row.thumbnail" alt="" style="width: 300px">
                  <div slot="reference" @click="opengoodsclassify(row)">
                    <img :src="row.web_thumbnail || row.thumbnail" class="goods-cover" alt="" />
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="商品名称">
              <template slot-scope="{row}">
                <!-- <span style="text-align: start;">
                  {{ row?.name?.replaceAll('amp;', '')?.replaceAll(' ', '&nbsp;') }}
                </span> -->
                <!-- tips悬浮提示 -->
                <el-tooltip placement="top" v-model="row.showTooltip" :open-delay="500" effect="dark"
                  :disabled="!row.showTooltip">
                  <div slot="content">
                    {{ row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
                  </div>
                  <div @mouseenter="showTips($event, row)" class='ellipsis-multiline' style="text-align: start;">
                    {{ row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="数量" width="191"></el-table-column>
            <el-table-column prop="price" label="单价" width="191">
              <template slot-scope="{row}">
                <div v-if="row.type === 'order_sn'">
                  <div style="display: flex;justify-content: space-between;align-items: center;padding-right: 10px;">
                    <div style="font-weight: 600;">订单编号：{{ row.trade_sn }}<el-tag type="danger" v-if="canelTrade.includes(row.trade_sn)">已取消</el-tag></div>
                    <div style="">收货人信息：
                      {{ row.name + '&nbsp&nbsp' +
                        row.mobile + '&nbsp&nbsp' +
                        row.province + row.city + row.county + row.town + row.address }}
                    </div>
                  </div>
                </div>
                <div v-else-if="row.type === 'order_remark'">
                  <div style="display: flex;justify-content: space-between;align-items: center;padding-right: 10px;">
                    <div>订单备注：{{ row.order_remark }}</div>
                    <div>
                      <span>商品总金额：{{ row.enterprise_goods_price }}</span>
                      <span style="margin: 0 20px 0 10px;">运费： {{ row.enterprise_freight_price }}</span>
                      <span>订单总金额：{{ row.enterprise_total_price }}</span>

                    </div>
                  </div>
                </div>
                <div v-else-if="row.type === 'empty'"></div>
                <div v-else>{{ row.enterprise_revise_price }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="enterprise_subtotal" label="金额" width="191"></el-table-column>
            <el-table-column label="操作" width="191">
              <template slot-scope="{row}">
                <el-button type="primary" size="mini" @click="handleOperateOrder(row)">查看订单</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="submit">
          <div class="balance">
            <div class="balance-left">
              <span>
                总计订单：{{ cartsInfo.data.order_num }}个 商品金额总计：{{ cartsInfo.data.goods_money }} 运费金额总计：{{
                  cartsInfo.data.freight
                }}
              </span>
            </div>
            <div class="dingdan">
              <div :style="{
                fontSize: '14px',
              }" class="order">
                <span>订单金额总计：</span>
                <span style="color: #b8741a;font-size: 20px;">{{ cartsInfo.data.order_money }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderList, payTrade } from '@/api/shopGoodsPurchase'
export default {
  name: 'ManagerOneClickDeliveryImportOrderDetail',
  data () {
    return {
      batch_sn: '',
      tableData: [],
      cartsInfo: { data: { trade_batch_do: {} } },
      canelTrade:[],//取消订单集合
    };
  },
  created () {
    this.batch_sn = this.$route.query.batch_sn
    console.log(this.$route, this.batch_sn);
  },
  mounted () {
    this.handleTableData()
  },

  methods: {
    showTips (obj, row) {
      /*obj为鼠标移入时的事件对象*/

      /*currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
      let TemporaryTag = document.createElement('span');
      TemporaryTag.innerText = row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
      TemporaryTag.className = 'getTextWidth';
      document.querySelector('body').appendChild(TemporaryTag);
      let currentWidth = document.querySelector('.getTextWidth').offsetWidth;
      document.querySelector('.getTextWidth').remove();

      /*cellWidth为表格容器的宽度*/
      const cellWidth = obj.target.offsetWidth

      /*当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
      currentWidth <= (2 * cellWidth) ? row.showTooltip = false : row.showTooltip = true
    },
    /**获取订单车列表 */
    async handleTableData () {
      if (!this.batch_sn) return
      const res = await getOrderList(this.batch_sn)
      const list = []
      this.cartsInfo = res
      this.cartsInfo.data.normal_order.forEach((item, index) => {
        list.push({ type: 'order_sn', trade_sn: item.trade_sn, ...item.consignee })
        item.order_list.forEach(item => {
          item.sku_list.forEach(sku => {
            sku.trade_sn = item.trade_sn
            sku.rowspan = item.sku_list.length
            sku.showTooltip = false
            list.push({ ...sku })
          })
        })
        const order_remark = item.order_list?.[0]?.remark ?? ''
        list.push({ type: 'order_remark', ...item.price_detail, order_remark })
        if (index !== this.cartsInfo.data.normal_order.length - 1) list.push({ type: 'empty' })
      })
      this.tableData = list;
      if (this.cartsInfo.data.canel_trade.length>0) {  
        this.canelTrade = this.cartsInfo.data.canel_trade.map(k=>k.trade_sn);
      }
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (!row.type && columnIndex === 5) return [row.rowspan, 1]
      if (!row.type) return
      if (columnIndex === 3) return [1, 7]
      return [0, 0]
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (!row.type) return 'cellStyle'
      const type = {
        order_sn: 'cellStyle2',
        order_remark: 'cellStyle2',
        empty: ''
      }
      return type[row.type]
    },
    /**订单支付 */
    async orderPay () {
      const res = await payTrade({ batch_sn: this.batch_sn })
      if (res.code === -1) return this.$message.error(res.message)
    },
    goBack () {
      this.$router.go(-1)
    },
    handleOperateOrder (row) {
      console.log(row);
      let route = this.$route.path.split("/")[2];
      this.$router.push({
        path: `/order/${route}/detail/${row.trade_sn}?type=${1}`
      });

    },
  },
};
</script>
<style lang="scss">
.headerStyle1 {
  font-weight: 400;
  background-color: #f3f3f3 !important;

  &>div {
    text-align: center;
    color: #333;
  }

}

.cellStyle {
  &>div {
    text-align: center;
  }
}

.cellStyle2 {
  // background-color: #d7d7d7 !important;

  &>div {
    text-align: center;
  }
}
</style>
<style lang="scss" scoped>
.content {
  background-color: #fff;
  min-height: 90vh;
}

.toolbar-title {
  height: 25.5px;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  padding-left: 4px;
  margin-right: 12px;

  &::before {
    content: ' ';
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #1A43A9;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translate(0, -50%);
  }
}

.back_btn {
  width: 36px;
  height: 36px;
  background-color: #1A43A9;
  border-radius: 50%;

  img {
    width: 10px;
    height: 20px;
    position: relative;
    top: -2px;
    left: -4px;
  }
}

.goods-cover-wrapper {
  padding: 0;

  .cell {
    padding: 0;
    text-align: center;

    span,
    a {
      display: inline-block;
    }
  }

  .goods-cover {
    width: 52px;
    height: 52px;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }
}

.submit {
  height: 61px;
  margin-top: 8px;
  display: flex;

  .balance {
    flex: 1;
    display: flex;
    justify-content: space-between;

    &-left {
      line-height: 61px;
    }

    .dingdan {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .order {
      margin-right: 10px;
    }

    .zhandian {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 5px;
      margin-right: 10px;
      font-weight: 400;
    }
  }

  .button {
    width: 152px;
    height: 100%;
    font-size: 16px;
    line-height: 61px;
    text-align: center;
    background-color: #b8741a;
    color: #fff;
    cursor: pointer;
  }
}
</style>